/* You can add global styles to this file, and also import other style files */

/*****************************************************************************
    Variables
******************************************************************************/

$font-family-custom: "Nunito", sans-serif;
$default: #617182;
$secondary: #5a6268;
$red: #ff0026;
$primary: #22a2d7;
$accent: #3a5896;
$danger: #ff5c75;
$light: #f0f6ff;
$white: #ffffff;
$muted: #839bb3;
$dark: #323a48;

/*****************************************************************************
    Reset
******************************************************************************/
body,
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu,
.site-logo li .brand-text,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.nav.metismenu,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-custom;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
a {
  cursor: pointer !important;
}
a:link,
a:visited {
  color: $primary;
}
a.default-color:active,
a.default-color:hover,
a:active,
a:hover {
  color: darken($primary, 5%);
}
.pointer {
  cursor: pointer;
}
body #app .content-wrapper {
  overflow-x: hidden !important;
}
.text-underline {
  text-decoration: underline !important;
}

.text-primary {
  color: $primary !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: darken($primary, 5%) !important;
}
.text-accent {
  color: $accent !important;
}

.bg-light {
  background-color: $light !important;
}
.bg-custom-blue {
  background: #b4c6e7 !important;
}
.bg-custom-yellow {
  background: #ffd966 !important;
}

.o-cover {
  object-fit: cover;
}
.o-contain {
  object-fit: contain;
}
.width-100 {
  width: 100% !important;
}

strong {
  font-weight: 600;
}

/*****************************************************************************
    Button
******************************************************************************/

.btn-primary,
a.btn-primary,
a:visited.btn-primary a:link.btn-primary {
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
}
.btn-primary:hover,
a.btn-primary:hover,
a:visited.btn-primary a:link.btn-primary:hover {
  background-color: darken($primary, 5%) !important;
  border: 1px solid darken($primary, 5%) !important;
  box-shadow: 0 3px 6px rgb(34, 162, 215, 0.4) !important;
  color: $white;
}
.btn-primary:active,
a.btn-primary:active,
a:visited.btn-primary a:link.btn-primary:active {
  background-color: darken($primary, 5%) !important;
  border: 1px solid darken($primary, 5%) !important;
  color: $white !important;
  box-shadow: none !important;
}
.btn-primary:focus,
a.btn-primary:focus,
a:visited.btn-primary a:link.btn-primary:focus {
  background-color: darken($primary, 5%);
  border: 1px solid darken($primary, 5%);
  color: $white !important;
  box-shadow: none !important;
}
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
  color: #fff;
  background-color: rgb(34, 162, 215, 0.8) !important;
  border-color: rgba(34, 162, 215, 0.8) !important;
  box-shadow: none !important;
}
.btn-primary:disabled {
  cursor: not-allowed !important;
}
.btn-secondary,
a:link.btn-secondary {
  color: $secondary;
}
.btn-secondary:hover,
a:link.btn-secondary:hover {
  background-color: $secondary !important;
  border: 1px solid $secondary !important;
  box-shadow: 0 5px 10px rgba(120, 141, 180, 0.4) !important;
  color: $white !important;
}
.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: $secondary;
  border-color: $secondary;
}
.btn-secondary:focus,
a:link.btn-secondary:focus {
  background-color: $secondary !important;
  border: 1px solid $secondary !important;
}
.btn-secondary:active,
a:link.btn-secondary:active {
  background-color: $secondary !important;
  border: 1px solid $secondary !important;
}
.btn-primary2 {
  background-color: #6e85af !important;
  border-color: #6e85af;
  color: $white;
}
.btn-primary2:hover {
  color: $white;
}
.btn-secondary:hover i,
.btn-secondary:focus i {
  color: #fff !important;
}
.btn-link {
  color: $primary;
  &:hover {
    color: darken($primary, 10%);
  }
}
.btn-icon-only {
  width: 30px !important;
  height: 25px !important;
  text-align: center !important;
  padding: 0px !important;
}
.btn-secondary:not([disabled]):not(.disabled).active i,
.btn-secondary:not([disabled]):not(.disabled):active i,
.show > .btn-secondary.dropdown-toggle i {
  color: #fff;
}

/*****************************************************************************
    Form
******************************************************************************/

.form-control:focus {
  border: 1px solid $primary;
}
.custom-select {
  font-size: 0.875rem;
  &:disabled {
    border: 1px solid #dfe7f3;
    background-color: #f0f6ff;
    color: #617182;
  }
}
.form-row {
  margin-left: -7.5px;
  margin-right: -7.5px;
  .col,
  [class*="col-"] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}
label em,
.control-label em {
  font-style: normal;
  color: $red;
  font-weight: normal;
  font-family: arial;
}

.has-error {
  .ng-select .ng-select-container {
    border-color: $danger;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-control-label::before {
  border: transparent solid 1px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: transparent;
}

/* NgSelect */
.ng-select .ng-select-container {
  border: 1px solid #dfe7f3;
  background-color: #fff;
  color: $dark;
  position: relative;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  font-size: 0.875rem;
  font-weight: normal;
  border-radius: 0.25rem;
}
.ng-select:not(.ng-select-multiple)
  .ng-select-container
  .ng-value-container
  .ng-input {
  /* width: 100%; */
  top: 0;
  padding: 0.375rem 2.7rem 0.375rem 0.75rem;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  vertical-align: 1px;
}
.ng-select.ng-select-opened .ng-arrow-wrapper .ng-arrow {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.ng-dropdown-panel .ng-dropdown-panel-items {
  box-shadow: 0 10px 20px rgba(194, 207, 228, 0.7);
  background-color: #fff;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 0.875rem;
  padding: 6px 0.75rem;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  color: #292b2c;
  background-color: #f0f6ff;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $primary;
  color: #fff;
}
// .ng-select .ng-clear-wrapper {
//   display: none;
// }
.ng-select .ng-clear-wrapper .ng-clear {
  font-family: Arial, Helvetica, sans-serif;
  vertical-align: -2px;
}
.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  border: 1px solid #dfe7f3;
  background-color: #f0f6ff;
  padding: 0 5px;
  margin: 2.5px 5px 2.5px 0;
  border-radius: 4px;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 2px;
}
.input-group > .ng-select {
  position: relative;
  /* flex: 1 1 auto; */
  width: 1%;
  margin-bottom: 0;
}
.input-group .ng-select:not(:first-child) .ng-select-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}
.input-group .ng-select:not(:last-child) .ng-select-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.ngselect2-modal .ng-dropdown-panel {
  position: relative;
}

/* Switch */
.tgl + .tgl-btn {
  width: 3em;
  height: 1.5em;
}
.slider.slider-primary .noUi-connect,
.slider.slider-primary.noUi-connect,
.tgl-flat.tgl-flat-primary:checked + .tgl-btn:after,
.tgl-primary:checked + .tgl-btn {
  background-color: $primary;
}

/*****************************************************************************
    Header & Sidebar
******************************************************************************/

body .top-toolbar {
  &.navbar-desktop,
  &.navbar-mobile.navbar-tablet {
    background: $accent;
  }
}
body .aside-toolbar {
  background: $accent;
}
body.layout-fixed .aside-toolbar {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: fixed;
  width: 255px;
  top: 0;
  z-index: 1000;
}
.sidebar.sidebar-left .sidebar-content {
  white-space: nowrap;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a {
  font-size: 0.875rem;
  font-weight: 600;
}
.sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  .nav-sub
  > li
  > a {
  font-weight: 400;
}
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a:focus,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a:hover {
  border-left-color: $primary;
  color: $primary;
}
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .metismenu
  .open
  > a:focus,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .metismenu
  .open
  > a:hover,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a
  > i,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown.active
  > ul
  > li.nav-dropdown.active
  > ul
  > li.active
  > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown
  > ul
  > li.nav-dropdown
  > ul
  > li
  > a:hover,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li
  > a:active,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li
  > a:hover {
  color: $primary;
}
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a:focus,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a:hover {
  border-left-color: $primary;
}
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  li
  a:hover,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  li
  a:hover
  i {
  color: $primary !important;
}


.form-control .fileinput-filename {
  max-width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > .nav-dropdown.active
  > .nav-sub {
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(25%, $primary),
      color-stop(50%, $primary),
      color-stop(75%, $primary),
      to($primary)
    )
    left bottom no-repeat #fff;
  background: linear-gradient(
      180deg,
      $primary 25%,
      $primary 50%,
      $primary 75%,
      $primary 100%
    )
    left bottom no-repeat #fff;
  background-size: 3px 100%;
}
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > .nav-dropdown.active
  > a {
  border-left-color: $primary;
}
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown
  > ul
  > li.nav-dropdown
  > ul
  > li
  > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.nav-dropdown
  > ul
  > li.nav-dropdown
  > ul
  > li
  > a
  > i,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li
  > a
  > i {
  color: #8696a9;
}
body {
  position: relative;
}

.metismenu .has-arrow:after {
  position: absolute;
  content: "";
  width: 0.5em;
  height: 0.5em;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: initial;
  right: 1em;
  transform: rotate(-45deg) translateY(-50%);
  transform-origin: top;
  top: 50%;
  transition: all 0.3s ease-out;
}
.metismenu .active > .has-arrow:after,
.metismenu .has-arrow[aria-expanded="true"]:after {
  transform: rotate(-135deg) translateY(-50%);
}

.noti-count {
  position: absolute;
  top: 16px;
  right: 16px;
  text-align: center;
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  background: #f33737;
  color: $white;
  // border: 1px solid $primary;
}

body.mini-sidebar .sidebar-left:hover .sidebar-content {
  // white-space: normal;
}
body.mini-sidebar
  .sidebar-left:not(:hover)
  .main-menu
  .nav.metismenu
  > li
  > a
  br {
  display: none;
}
body.mini-sidebar
  .sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  .collapse.show {
  display: none;
}
body.mini-sidebar .sidebar-left:hover .main-menu .nav.metismenu .collapse.show {
  display: block;
}
body.mini-sidebar .sidebar-left .sidebar-content .btn-menu {
  opacity: 1;
}
body.mini-sidebar .sidebar-left .site-logo li .logo {
  white-space: nowrap;
}
body.mini-sidebar .sidebar-left .site-logo img {
  max-width: none;
}
body.mini-sidebar .sidebar-left:not(:hover) .site-logo {
  opacity: 0;
}
body.mini-sidebar #app .content-wrapper .content {
  padding-left: 60px;
}
body.mini-sidebar #app .content-wrapper {
  padding-left: 0px;
}
.aside-toolbar .header-controls {
  left: 11px;
}

/*****************************************************************************
    Card
******************************************************************************/

.equalHeight {
  min-height: calc(100% - 1.875rem);
}

.card-footer .btn ~ .btn {
  margin-left: 10px;
}
.card.card-tabs .nav.nav-tabs li a {
  font-size: 0.875rem;
  height: 60px;
  padding: 1.4rem 1.5rem 1.3rem;
  line-height: 1.1;
  font-weight: normal;
}
.nav-tabs {
  margin-bottom: 0px;
}
.tab-pane {
  position: relative;
}
.tab-pane .actions.top-right {
  top: -47px;
}
.inland-card {
  position: absolute;
  bottom: -320px;
  left: -15px;
  width: 111%;
  display: block;
  right: 0px;
}

/*****************************************************************************
    Dropdown
******************************************************************************/

.dropup .dropdown-toggle::after,
.dropdown-toggle::after {
  display: none;
}
.dropdown.filters .dropdown-menu,
.dropdown300 {
  width: 300px !important;
}

.dropdown .dropdown-menu .dropdown-item.active,
.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item.active,
.dropleft .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:focus,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item.active,
.dropright .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:focus,
.dropright .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item.active,
.dropup .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:focus,
.dropup .dropdown-menu .dropdown-item:hover {
  color: $primary !important;
}
.dropdown .dropdown-menu .dropdown-item.active i,
.dropdown .dropdown-menu .dropdown-item:active i,
.dropdown .dropdown-menu .dropdown-item:focus i,
.dropdown .dropdown-menu .dropdown-item:hover i,
.dropleft .dropdown-menu .dropdown-item.active i,
.dropleft .dropdown-menu .dropdown-item:active i,
.dropleft .dropdown-menu .dropdown-item:focus i,
.dropleft .dropdown-menu .dropdown-item:hover i,
.dropright .dropdown-menu .dropdown-item.active i,
.dropright .dropdown-menu .dropdown-item:active i,
.dropright .dropdown-menu .dropdown-item:focus i,
.dropright .dropdown-menu .dropdown-item:hover i,
.dropup .dropdown-menu .dropdown-item.active i,
.dropup .dropdown-menu .dropdown-item:active i,
.dropup .dropdown-menu .dropdown-item:focus i,
.dropup .dropdown-menu .dropdown-item:hover i {
  color: $primary !important;
}
.dropdown .dropdown-menu,
.dropleft .dropdown-menu,
.dropright .dropdown-menu,
.dropup .dropdown-menu,
.input-group .dropdown-menu {
  width: 150px;
}
.dropdown-menu-accout {
  width: 250px !important;
}
.dropdown-item {
  font-size: 0.875rem;
  padding: 10px 20px;
  border-left: 2px solid transparent;
}
.dropdown-status-menu.dropdown-menu .dropdown-item i,
.dropdown-menu .dropdown-item i.fa-circle {
  vertical-align: inherit !important;
  font-size: 10px !important;
  margin-right: 5px;
}
.dropdown-menu {
  .text-success {
    color: #00a65a !important;
  }
  .text-danger {
    color: #dd4b39 !important;
  }
}

// .dropdown, .dropleft, .dropright, .dropup {
//   z-index: 9999 !important;
// }
.dropdown .dropdown-menu.dropdown-menu-right,
.dropleft .dropdown-menu.dropdown-menu-right,
.dropright .dropdown-menu.dropdown-menu-right,
.dropup .dropdown-menu.dropdown-menu-right,
.input-group .dropdown-menu.dropdown-menu-right {
  z-index: 9999 !important;
}
.card.card-notification .card-body {
  overflow-y: auto;
}

.input-group ngb-datepicker.dropdown-menu {
  min-width: 10rem !important;
  top: auto !important;
  width: auto !important;
}

ngb-datepicker {
  .ngb-dp-weekdays,
  .ngb-dp-header {
    background-color: $light;
    border-color: rgb(223, 231, 243);
  }
  .ngb-dp-weekday {
    color: $primary;
  }
  .btn-light {
    &:hover,
    &:active {
      box-shadow: none !important;
    }
  }
  .bg-primary:not(:hover) {
    color: #fff !important;
  }
  .ngb-dp-today .btn-light:not(:hover) {
    color: #fff !important;
    background-color: $accent !important;
  }
}

span.flatpickr-weekday {
  color: $default;
}
.flatpickr-day {
  color: $default;
  &.today {
    border-color: $accent;
    background: $accent;
    color: #fff;
    &:hover,
    &:focus {
      border-color: $primary;
      background: $primary;
    }
  }
  &.inRange,
  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:hover,
  &.prevMonthDay:hover,
  &.nextMonthDay:hover,
  &:focus,
  &.prevMonthDay:focus,
  &.nextMonthDay:focus {
    background: $light;
    border-color: $light;
  }
  &.selected,
  &.startRange,
  &.endRange,
  &.selected.inRange,
  &.startRange.inRange,
  &.endRange.inRange,
  &.selected:focus,
  &.startRange:focus,
  &.endRange:focus,
  &.selected:hover,
  &.startRange:hover,
  &.endRange:hover,
  &.selected.prevMonthDay,
  &.startRange.prevMonthDay,
  &.endRange.prevMonthDay,
  &.selected.nextMonthDay,
  &.startRange.nextMonthDay,
  &.endRange.nextMonthDay {
    border-color: $primary;
    background: $primary;
  }
  &.selected.startRange + .endRange,
  &.startRange.startRange + .endRange,
  &.endRange.startRange + .endRange {
    box-shadow: -10px 0 0 $primary;
  }
  &.inRange {
    box-shadow: -10px 0 0 $light;
  }

  &.flatpickr-disabled,
  &.flatpickr-disabled:hover {
    color: #bbc6d0 !important;
    background-color: #fff !important;
    border-color: #fff !important;
  }

  &.nextMonthDay,
  &.prevMonthDay {
    color: $muted;
  }
}

/*****************************************************************************
    Page Header
******************************************************************************/

.page-header .btn,
a:link.btn,
.removebtn,
.button-with-textbox {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 35px;
}
.page-header .input-group .input-group-text {
  line-height: 0.9;
}
.page-content {
  padding-top: 20px;
}
.site-logo {
  li .logo {
    top: 50%;
    transform: translateY(-50%);
    left: 60px;
  }
  sup {
    top: -12px;
  }
}

/* ********************************************************************************************
    Login
*********************************************************************************************** */

.login-box,
.register-box {
  width: 100%;
  max-width: 410px;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07),
    0 2px 9px 0 rgba(18, 106, 211, 0.06);
  margin: 5% auto;
}
.login-logo,
.register-logo {
  background: $accent;
  margin-bottom: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;
}
.login-box-msg,
.register-box-msg {
  font-weight: normal;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 30px;
  text-align: center;
}
.login-box-body,
.register-box-body {
  color: inherit;
  padding: 30px 25px;
  background: #fff;
}
.login-box-body .form-control {
  background-color: #f0f6ff;
  border-color: transparent;
  font-size: 15px;
  border-radius: 50px;
  height: 49px;
  text-align: center;
}
.login-box-body .form-control:focus {
  background-color: #fff;
  border-color: #9c9c9c;
}
.login-box-body .btn {
  font-size: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 50px;
  margin-top: 15px;
}
.login-box-footer {
  background-color: $light;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding: 20px;
  font-size: 14px;
}
.custom-control-label {
  cursor: pointer;
}
.main-container {
  position: relative;
  min-height: 100vh;
}
.custom-checkbox.checkbox-primary
  .custom-control-input:checked
  ~ .custom-control-label::before,
.custom-checkbox.checkbox-primary
  .custom-control-input:indeterminate
  ~ .custom-control-label::before,
.custom-radio.radio-primary
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: $primary;
}
.third-party-checkbox .custom-control-label::before,
.third-party-checkbox .custom-control-label::after {
  top: 2px;
}

/*****************************************************************************
    Table
******************************************************************************/

// .table-responsive:not(.dashboard-table):not(.dashboard-table0) {
//   min-height: 270px;
// }
.table {
  color: $default;
  .custom-control-label {
    vertical-align: top;
  }
  thead th {
    position: sticky;
    top: 0;
    font-weight: 600;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #ffffff;
    z-index: 1;
    border-bottom: none !important;
    &::after,
    &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
    }
    &::after {
        bottom: 0px;
        border-bottom: 1px solid rgba(234,243,253,.9);
    }
    &.sorting {
        padding-right: 25px;
    }
  }
  tbody tr:first-child td {
    border-top: none !important;
}
  th {
    white-space: nowrap;
  }
  td {
    font-weight: 400;
  }
  td,
  th {
    vertical-align: middle;
  }
  td:first-child,
  th:first-child {
    padding-left: 1.25rem;
  }

  .table-field-actions,
  .table-field-status {
    text-align: center;
    width: 120px !important;
    .table-actions {
      position: absolute;
      margin-top: -11px;
      margin-left: 32px;
      .dropdown-item i {
        margin-right: 5px;
      }
      a {
        cursor: pointer;
      }
      .label {
        padding: 0.05em 0.5em 0.1em;
      }
    }
    .dropdown-menu {
      width: 150px;
    }
    .btn-group:not(.dropup) {
      .dropdown-menu {
        top: 15% !important;
      }
    }
  }
  .table-field-status {
    text-align: left;
    .dropdown {
      margin-left: 0px;
      .dropdown-toggle .icon {
        vertical-align: middle;
        margin-left: 2px;
      }
    }
    .dropdown-menu .dropdown-item i {
      vertical-align: inherit !important;
      font-size: 10px !important;
      margin-right: 5px;
    }
    .dropdown-menu {
      .text-success {
        color: #00a65a !important;
      }
      .text-danger {
        color: #dd4b39 !important;
      }
    }
  }
  .sorting span {
    position: relative;
    &::before {
      position: absolute;
      top: -4px;
      display: block;
      opacity: 1;
      right: -0.9em;
      content: "\2191";
      cursor: pointer;
    }
    &::after {
      position: absolute;
      top: -4px;
      display: block;
      opacity: 1;
      right: -1.4em;
      content: "\2193";
      cursor: pointer;
    }
  }
  &.remove-padding {
    tr {
      th:first-child,
      td:first-child {
        padding-left: 0px;
      }
      th:last-child,
      td:last-child {
        padding-right: 0px;
      }
    }
  }
  &.remove-border {
    th,
    td{
        border-width: 0px !important;
        padding: .60rem;
    }
    thead th::after {
        border-bottom: none !important;
    }
  }
  &.table-hover tbody tr:hover {
    background-color: rgba(231, 241, 253, 0.2);
  }
}

.pagination .page-item.active .page-link {
  padding: 0px;
  line-height: 30px;
}
.pagination .page-item.active .page-link {
  background-color: $primary;
  border-color: $primary;
}
.pagination a:hover {
  color: $primary;
}
.table .label:not(.label-default),
.view-label {
  font-weight: normal;
  font-size: 12px;
  background-color: transparent !important;
  border: 1px solid #e4eef0;
  color: #8390a0 !important;
  border-radius: 50px;
  padding: 0.2em 0.6em 0.2em;
  white-space: nowrap;
}
.table .label:not(.label-default):before,
.view-label:before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #d2d6de;
  border-radius: 100%;
  display: inline-block;
  margin-right: 7px;
}

.label-gray:before {
  background-color: #d2d6de !important;
}
.label-green:before,
.label-success:before {
  background-color: #00a65a !important;
}
.label-red:before,
.label-danger:before {
  background-color: #dd4b39 !important;
}
.label-aqua:before,
.label-info:before {
  background-color: #00c0ef !important;
}
.label-yellow:before,
.label-warning:before {
  background-color: #f39c12 !important;
}
.label-purple:before {
  background-color: #605ca8 !important;
}
.label-orange:before {
  background-color: #ff7f5e !important;
}
.label-blue:before {
  background-color: #0e9fff !important;
}
.label-light-green:before {
  background-color: #90ee90 !important;
}

.btn-group .dropdown-toggle i {
  font-size: 1.375rem !important;
  vertical-align: middle !important;
}

/*****************************************************************************
    Chart
******************************************************************************/

.pie-chart-legend {
  .table {
    font-size: 16px;
    margin: 0px auto;
    max-width: 95%;
    white-space: nowrap;
    th {
      padding-top: 0px;
      white-space: normal;
      font-size: 0.875rem;
    }
    th:first-child,
    td:first-child {
      padding-left: 0px;
    }
    th:last-child,
    td:last-child {
      padding-right: 0px;
    }
    .value {
      font-weight: 600;
    }
    .colorbox {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      display: inline-block;
      vertical-align: -1px;
      margin-right: 6px;
    }
  }
}

/* **************************************************************************************
    MD Drppicker
*************************************************************************************** */

.md-drppicker {
  top: 100% !important;
  margin-top: 5px !important;
  font-family: $font-family-custom !important;
  padding: 0px !important;
  box-shadow: 0 4px 14px rgba(174, 197, 231, 0.5) !important;
  -webkit-box-shadow: 0 4px 14px rgba(174, 197, 231, 0.5) !important;
  /* width: 150px !important; */
  left: auto !important;
  right: 0px !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.md-drppicker .ranges {
  width: 150px !important;
  background-color: rgba(231, 241, 253, 0.2);
}
.md-drppicker .ranges ul li {
  outline: none !important;
  color: #504c4c !important;
  font-size: 13px !important;
  outline: none !important;
  background-color: rgba(231, 241, 253, 0);
  border: 1px solid #fafcff !important;
}
.md-drppicker .ranges ul li:hover {
  background-color: rgba(231, 241, 253, 0.8) !important;
  border-color: #fafcff !important;
}
.md-drppicker .ranges ul li button {
  outline: none !important;
  color: #617182;
}
.md-drppicker .ranges ul li button.active,
.md-drppicker .ranges ul li button:active {
  background-color: $primary !important;
  color: #fff !important;
  outline: none !important;
}
.md-drppicker .calendar td,
.md-drppicker .calendar th {
  padding: 5px 5px !important;
  color: #617182 !important;
  border: none !important;
  border-radius: 4px !important;
}
.md-drppicker .calendar td.start-date:not(.end-date) {
  border-radius: 2em 0px 0px 2em !important;
}
.md-drppicker .calendar td.in-range {
  border-radius: 0px !important;
}
.md-drppicker .calendar td.end-date:not(.start-date) {
  border-radius: 0px 2em 2em 0px !important;
}

.md-drppicker th.month {
  font-weight: 600;
}
.md-drppicker td {
  opacity: 1 !important;
}
.md-drppicker td.in-range {
  background-color: #f0f6ff !important;
}
.md-drppicker td.available:hover,
.md-drppicker th.available:hover {
  background-color: #f0f6ff !important;
  color: #617182 !important;
}
.md-drppicker td.off,
.md-drppicker td.off.end-date,
.md-drppicker td.off.in-range,
.md-drppicker td.off.start-date {
  color: #839bb3 !important;
}
.md-drppicker td.active,
.md-drppicker td.active:hover,
.md-drppicker td.today.active,
.md-drppicker td.off.active {
  background-color: $accent !important;
  color: #fff !important;
}
.md-drppicker td.today {
  background-color: $primary !important;
  color: #fff !important;
}

.md-drppicker .buttons {
  width: 100px;
}
.md-drppicker .btn {
  margin: 5px 0px;
  width: 100%;
  display: block;
  font-family: $font-family-custom !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.md-drppicker .btn-default:not(.clear) {
  background-color: #fff !important;
  border-color: #e4e7ea !important;
  color: #5c6873 !important;
  border: 1px solid #ddd !important;
}
.md-drppicker .btn:not(.btn-default) {
  background-color: $primary !important;
  border-color: $primary !important;
  color: #fff !important;
}

/* **************************************************************************************
    Modal
*************************************************************************************** */

.modal-title {
  font-weight: 600;
}
.modal-footer {
  border-top: 1px solid rgba(234, 243, 253, 0.7);
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 3.5rem);
  .modal-content {
    max-height: calc(100vh - 3.5rem);
    > form {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      max-height: 100%;
    }
  }
}

/* **************************************************************************************
    Others
*************************************************************************************** */
.pl-34 {
  padding-left: 34px;
}
.actions .btn {
  height: 35px;
  padding: 6px 20px;
  &.top-right {
    top: 13px;
  }
}
.accordion > .card > .card-header .btn-link:after {
  top: 50%;
  right: 5.3em;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 1px solid #e9e9e9;
}
.accordion > .card > .card-header .btn-link {
  font-size: 1rem;
  font-weight: 600;
  color: $default;
}

/*Rating*/
.rating-container {
  width: 100px;
  height: 19px;
  font-size: 0;
  line-height: 0;
  background: url(assets/img/ratings_large.png) 0 0 repeat-x;
  text-indent: -999em;
  overflow: hidden;
  background-size: 20px;
}

.rating-container .rating-stars {
  /*cursor: pointer;*/
  float: left;
  height: 19px;
  background: url(assets/img/ratings_large.png) 0 100% repeat-x;
  background-size: 20px;
}

.rating-sm.rating-container {
  width: 80px;
  height: 17px;
  font-size: 0;
  line-height: 0;
  background: url(assets/img/ratings_large.png) 0 0 repeat-x;
  text-indent: -999em;
  overflow: hidden;
  background-size: 16px;
}

.rating-sm.rating-container .rating-stars {
  /*cursor: pointer;*/
  float: left;
  height: 15px;
  background: url(assets/img/ratings_large.png) 0 100% repeat-x;
  background-size: 16px;
}
.rating-lg.rating-container {
  width: 175px;
  height: 34px;
  font-size: 0;
  line-height: 0;
  background: url(assets/img/ratings_large.png) 0 0 repeat-x;
  text-indent: -999em;
  overflow: hidden;
  background-size: 35px;
}

.rating-lg.rating-container .rating-stars {
  /*cursor: pointer;*/
  float: left;
  height: 34px;
  background: url(assets/img/ratings_large.png) 0 100% repeat-x;
  background-size: 35px;
}
.rating-box {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaf3fd !important;
}
.rating-box:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: none !important;
}

//Multiple Checkbox
.cuppa-dropdown {
  .c-btn {
    border: 1px solid #dfe7f3;
    min-height: 35px;
    border-radius: 0.25rem;
    color: $muted;
    font-weight: normal;
  }
  .selected-list {
    max-height: 80px;
    overflow-y: auto;
    .c-btn {
      padding: 5px 0.75rem;
    }
  }
  .selected-list .c-angle-down,
  .selected-list .c-angle-up {
    margin-top: -4px;
  }
  .list-area {
    border: 1px solid #dfe7f3;
    box-shadow: none;
  }
  .list-filter {
    border-bottom: 1px solid #dfe7f3;
    input {
      color: $dark;
    }
    .c-clear {
      top: 3px;
    }
    .c-search {
      top: 3px;
      svg {
        fill: $muted;
      }
    }
  }
  .selected-list .c-list .c-token {
    background: $primary;
  }
  .select-all {
    border-bottom: 1px solid #dfe7f3;
  }
  .pure-checkbox input[type="checkbox"]:checked + label:before {
    background: $primary;
    border: 1px solid $white;
  }
  .pure-checkbox input[type="checkbox"] + label {
    color: $default;
  }
  .pure-checkbox input[type="checkbox"] + label:before {
    color: $dark;
    border: 1px solid #c8d6ea;
  }
  // .dropdown-list ul li:hover {
  //   background: $primary;
  //   color: $primary;
  // }
  // li.pure-checkbox:hover input[type="checkbox"] + label {
  //   color: $primary;
  // }
  .arrow-2 {
    border-bottom: 15px solid #dfe7f3;
  }
}

/* ********************************************************************************************
    Responsive
*********************************************************************************************** */

@media only screen and (max-width: 991px) {
  .site-logo li .logo_mobile {
      position: relative;
      top: 14px;
      left: 0px;
  }
  .sidebar.sidebar-left .sidebar-content {
      top: 65px;
      -webkit-transform: none;
      transform: none;
  }
  body.layout-fixed .sidebar-left .sidebar-content .main-menu {
      margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-pane .actions {
    position: relative !important;
  }
  .tab-pane .actions.top-right {
    right: 0px !important;
    top: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .pagination {
    flex-wrap: wrap !important;
    margin-left: -13px !important;
    margin-top: 10px !important;
  }
  .actions.top-right li {
    margin-bottom: 8px !important;
  }
  .actions.top-right .m-l-10 .input-group,
  .actions.top-right .ml-0 .input-group {
    width: 270px !important;
  }
}

@media only screen and (max-width: 410px) {
}

/*Loader*/
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
}
.loader-logo {
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  height: 50px;
}
.loader-container .spinner {
  margin: 0 auto;
  width: 70px;
  height: 50px;
  text-align: center;
  font-size: 20px;
}
.loader-container .spinner > div {
  background-color: #333;
  height: 100%;
  margin: 0px 3px 0px 0px;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.loader-container .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.loader-container .spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.loader-container .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.loader-container .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.fileinput .form-control {
  display: flex;
  align-items: center;
}
.form-control .fileinput-filename {
  margin-left: 5px;
}
.fileinput .thumbnail > img {
  height: 150px;
  width: 150px;
  object-fit: cover;
}
.has-feedback {
  position: relative;
}
.has-feedback .form-control {
  padding-right: 55px;
}
.form-control-feedback {
  position: absolute;
  top: 39px;
  right: 15px;
  z-index: 2;
  font-size: 16px;
}
.form-control:disabled,
.form-control[readonly] {
  cursor: not-allowed;
}
app-control-error {
  width: 100%;
}

// Modal animate css

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.custom-validation {
  display: block !important;
}
// .input-group {
//   flex-wrap: unset !important;
// }

// input-group-custom
.input-group-custom {
  position: relative;
}
.input-group-append-custom {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9;
}
.input-group-custom .form-control {
  padding-right: 50px;
}
.input-group-text-custom {
  background-color: #f0f6ff;
  border: 1px solid #dfe7f3;
  color: #96a9c6;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-group-custom
  .form-control:focus
  ~ .input-group-append-custom
  .input-group-text-custom {
  border-left: 1px solid #22a2d7;
}

#map_wrapper {
  height: 400px;
}

.info_content {
  background-color: #fff;
  padding: 10px;
}

.info_location_name {
  color: #023981;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.info_location_text {
  margin-bottom: 10px;
  color: #767a7f;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.info_location_call {
  font-size: 14px;
  color: #2e3133;
  font-weight: 600;
}
.modal-body-height .modal-body {
  max-height: calc(100vh - 12rem);
}
.table-align-top.table td,
.table-align-top.table th {
  vertical-align: top !important;
}
.pac-container {
  z-index: 9999 !important;
}
.toast-container .ngx-toastr {
  width: 500px !important;
}

body .sidebar-left {
  position: fixed !important;
}
body #app .content-wrapper .content {
  padding-left: 255px;
}
body.layout-fixed .sidebar-left .sidebar-content .main-menu {
  margin-top: 65px;
  height: calc(100vh - 65px);
  padding-top: 0px;
  padding-bottom: 0px;
}

.table-responsive {
  max-height: calc(100vh - 292px);
}

// *******************stick column
.sticky-col{
  position: sticky !important;
  z-index: 1 !important;
  background-color: #ffff !important;
}
.table thead th.sticky-col {
  z-index: 2 !important;
}
.first-col {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0;  
}
.second-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 100px;
}
.third-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 250px;
  white-space: normal;
}

.break-list {width: 275px;
  white-space: normal;
}